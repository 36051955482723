import type { CSSProperties, FunctionComponent } from 'react'
import type { BannerClickArguments } from '@sporza/tracking/events'

import { Theme, ThemeConfig } from '@sporza/config'
import { useBannerImpression } from '@sporza/tracking'
import { bannerClickEvent } from '@sporza/tracking/events'
import { getPathName } from '@sporza/utils/urls'
import { useRef } from 'react'

import { Image } from '../../../molecules/image'
import { generateDefaultSrc } from '../../../molecules/image/helpers'

import styles from './play-banner.module.scss'

interface PlayBannerThemeConfig extends ThemeConfig {
  backgroundImageUrl?: string
  leftImageUrl?: string
  rightImageUrl?: string
  logoUrl?: string
  accentColor?: string
  texrtColor?: string
}

interface PlayBannerProps {
  themeConfig?: PlayBannerThemeConfig
  bannerTargetUrl?: string
  designSystemBaseUrl?: string
}

const PlayBanner: FunctionComponent<PlayBannerProps> = (
  {
    themeConfig,
    bannerTargetUrl
  }
) => {
  const getBannerContext = () => {
    switch(themeConfig?.theme) {
      case Theme.GenericPlayMadness:
        return {
          bannerTitle: 'go play - masters of madness',
          componentName: 'mediaplayer-banner',
          targetPageUrl: bannerTargetUrl,
          targetPageName: getPathName(bannerTargetUrl),
          targetPageId: bannerTargetUrl,
          bannerAdvertiser: 'Go Play - MastersofMadness'
        }
      case Theme.GenericPlay:
      default:
        return {
          bannerTitle: 'play sports - één abonnement verandert alles',
          componentName: 'mediaplayer-banner',
          targetPageUrl: bannerTargetUrl,
          targetPageName: getPathName(bannerTargetUrl),
          targetPageId: bannerTargetUrl,
          bannerAdvertiser: 'Telenet Play'
        }
    }
  }

  const {
    backgroundImageUrl,
    leftImageUrl,
    centerImageUrl,
    rightImageUrl
  } = themeConfig || {}

  const bannerRef = useRef(null)
  const bannerContext = getBannerContext()

  const trackBannerClick = (bannerContext: any) => {
    bannerClickEvent(bannerContext as BannerClickArguments)

    return true
  }

  useBannerImpression(
    {
      ref: bannerRef,
      ...bannerContext
    }
  )

  return <a
    className={styles.playBanner}
    style={backgroundImageUrl !== undefined ? { '--play-banner-background': `url("${generateDefaultSrc(`${backgroundImageUrl}`, 'width640hx')}")` } as CSSProperties : undefined}
    href={bannerTargetUrl}
    target="_blank"
    rel="noreferrer"
    ref={bannerRef}
    onClick={() => trackBannerClick(bannerContext)}
  >
    <div className={styles.left}>
      {leftImageUrl && <Image noCrop src={leftImageUrl} />}
    </div>
    <div className={styles.center}>
      {centerImageUrl && <Image noCrop src={centerImageUrl} />}
    </div>
    <div className={styles.right}>
      {rightImageUrl && <Image noCrop src={rightImageUrl} />}
    </div>
  </a>
}

export type {
  PlayBannerProps
}

export {
  PlayBanner
}
